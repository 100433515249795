import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Grid,
    Skeleton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Card,
    CardContent,
    CircularProgress
} from '@mui/material';
import dayjs from "dayjs";
import { formatSelectedTime } from "../../utils/formatSelectedTime";
import { cancelReservation, fetchFilteredUserList } from "../../api/api";

const TimeSlotSelector = ({
                              selectedTimeSlots,
                              setSelectedTimeSlots,
                              roomReservations,
                              selectedRooms,
                              loading,
                              selectedDate,
                              reservationData,
                              reservations,
                              openSnackbar,
                          }) => {
    const [isLoadingDelayed, setIsLoadingDelayed] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedReservations, setSelectedReservations] = useState([]);
    const [reservationUsers, setReservationUsers] = useState([]);
    const [clickedTimeSlot, setClickedTimeSlot] = useState(null);
    const [isIntercepting, setIsIntercepting] = useState(false);
    const [selectionStart, setSelectionStart] = useState(null);
    const [isSelecting, setIsSelecting] = useState(false);
    console.log(selectedRooms,reservationData);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoadingDelayed(false);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [loading]);

    const timeSlotToReservation = React.useMemo(() => {
        const mapping = {};
        reservations.forEach((reservation) => {
            reservation.reservation_time.forEach((timeSlot) => {
                if (!mapping[timeSlot]) {
                    mapping[timeSlot] = [];
                }
                mapping[timeSlot].push(reservation);
            });
        });
        return mapping;
    }, [reservations]);

    const timeSlotsOrder = useMemo(() => {
        const slots = [];
        let openingStart = "08:00";
        let openingEnd = "20:00";

        if (reservationData?.product?.additionally?.opening_hours_start && reservationData?.product?.additionally?.opening_hours_end) {
            openingStart = reservationData.product.additionally.opening_hours_start;
            openingEnd = reservationData.product.additionally.opening_hours_end;
        } else if (selectedRooms?.[0]?.additionally?.opening_hours_start && selectedRooms?.[0]?.additionally?.opening_hours_end) {
            openingStart = selectedRooms[0].additionally.opening_hours_start;
            openingEnd = selectedRooms[0].additionally.opening_hours_end;
        } else {
            console.warn('Не удалось найти opening_hours_start и opening_hours_end. Используются значения по умолчанию.');
        }

        const [startHour, startMinute] = openingStart.split(':').map(Number);
        const [endHour, endMinute] = openingEnd.split(':').map(Number);

        const startTime = dayjs(selectedDate)
            .hour(startHour)
            .minute(startMinute)
            .second(0);

        const endTime = dayjs(selectedDate)
            .hour(endHour)
            .minute(endMinute)
            .second(0);
        let currentTime = startTime;
        while (currentTime.isBefore(endTime)) {
            const nextTime = currentTime.add(30, 'minute');
            const timeInterval = `${currentTime.format('HH:mm')}-${nextTime.format('HH:mm')}`;
            slots.push(timeInterval);
            currentTime = nextTime;
        }

        return slots;
    }, [selectedDate, reservationData, selectedRooms]);

    const handleTimeSlotClick = (timeInterval) => {
        if (!isSelecting) {
            setSelectionStart(timeInterval);
            setSelectedTimeSlots([timeInterval]);
            setIsSelecting(true);
        } else {
            const startIndex = timeSlotsOrder.indexOf(selectionStart);
            const endIndex = timeSlotsOrder.indexOf(timeInterval);
            if (startIndex !== -1 && endIndex !== -1) {
                const [start, end] = startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
                const range = timeSlotsOrder.slice(start, end + 1);
                setSelectedTimeSlots(range);
            }
            setIsSelecting(false);
        }
    };

    const handleUnavailableTimeSlotClick = async (timeInterval) => {
        setClickedTimeSlot(timeInterval);
        const reservationsAtTime = timeSlotToReservation[timeInterval];
        if (reservationsAtTime && reservationsAtTime.length > 0) {
            setSelectedReservations(reservationsAtTime);
            const userIds = [...new Set(reservationsAtTime.map(reservation => reservation.created_by))];

            try {
                const userInfoResponse = await fetchFilteredUserList({ _id: userIds });
                const userInfoArray = userInfoResponse.data;
                if (Array.isArray(userInfoArray) && userInfoArray.length > 0) {
                    setReservationUsers(userInfoArray);
                    setDialogOpen(true);
                } else {
                    openSnackbar('Не удалось получить информацию о пользователях.', 'error');
                }
            } catch (error) {
                openSnackbar('Ошибка при получении информации о пользователях.', 'error');
            }
        }
    };

    const handleIntercept = async (reservationId, timeSlot) => {
        setIsIntercepting(true);
        try {
            await cancelReservation(reservationId, timeSlot);
            setDialogOpen(false);
            openSnackbar('Ваш запрос на перехват успешно отправлен создателю мероприятия!', 'success');
        } catch (error) {
            openSnackbar('Не удалось отправить запрос на перехват. Пожалуйста, попробуйте позже.', 'error');
        } finally {
            setIsIntercepting(false);
        }
    };

    const isTimeSlotUnavailable = (timeInterval) => {
        const roomsToCheck = reservationData?.product_id
            ? [{ id: reservationData?.product_id }]
            : selectedRooms || [];

        if (!Array.isArray(roomsToCheck)) {
            console.error('roomsToCheck is not an array:', roomsToCheck);
            return false;
        }

        const validRooms = roomsToCheck.filter((room, index) => {
            if (!room) {
                console.warn(`Encountered undefined or null room at index ${index} in roomsToCheck`);
                return false;
            }
            if (!room.id) {
                console.warn(`Room object missing id at index ${index}:`, room);
                return false;
            }
            return true;
        });

        if (validRooms.length === 0) {
            console.warn('No valid rooms to check for time slot availability.');
            return false;
        }

        return validRooms.every((room) => {
            const roomReservedTimeSlots = roomReservations[room.id] || [];
            return roomReservedTimeSlots.includes(timeInterval);
        });
    };

    const renderTimeSlots = () => {
        return timeSlotsOrder.map((timeInterval) => {
            const isUnavailable = isTimeSlotUnavailable(timeInterval);
            const isSelected = selectedTimeSlots.includes(timeInterval);
            const [start] = timeInterval.split('-');
            const startHour = parseInt(start.split(':')[0], 10);
            const startMinute = parseInt(start.split(':')[1], 10);
            const startTime = dayjs(selectedDate)
                .hour(startHour)
                .minute(startMinute)
                .second(0);
            const isToday = dayjs(selectedDate).isSame(dayjs(), 'day');
            const isPast = isToday && dayjs().isAfter(startTime);

            return (
                <Grid item xs={4} sm={3} md={2} key={timeInterval}>
                    <Box
                        onClick={() => {
                            if (isUnavailable || isPast) {
                                handleUnavailableTimeSlotClick(timeInterval);
                            } else {
                                handleTimeSlotClick(timeInterval);
                            }
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '100px',
                            height: '50px',
                            cursor: 'pointer',
                            backgroundColor: isPast
                                ? '#f0f0f0'
                                : isUnavailable
                                    ? '#ffebee'
                                    : isSelected
                                        ? '#e3f2fd'
                                        : 'transparent',
                            border: '1px solid',
                            borderColor: isPast || isUnavailable ? '#bdbdbd' : '#90caf9',
                            borderRadius: '8px',
                            color: isPast || isUnavailable ? '#bdbdbd' : '#000',
                            transition: 'background-color 0.3s, box-shadow 0.3s',
                            margin: '3px',
                            ...(isSelected && {
                                borderColor: '#1976d2',
                                boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)',
                            }),
                        }}
                    >
                        <Typography variant="body2" sx={{ textAlign: 'center' }}>
                            {timeInterval}
                        </Typography>
                    </Box>
                </Grid>
            );
        });
    };

    return (
        <>
            <Box>
                <Box
                    sx={{
                        maxHeight: { xs: '31dvh' },
                        border: '1px solid #90caf9',
                        borderRadius: '8px',
                        overflowY: 'auto',
                        padding: '0.2em',
                    }}
                >
                    {(loading || isLoadingDelayed) ? (
                        <Grid container spacing={1} justifyContent="center">
                            {Array.from(new Array(10)).map((_, index) => (
                                <Grid item xs={4} sm={3} md={2} key={index}>
                                    <Skeleton variant="rectangular" width={100} height={50} sx={{ margin: '3px', borderRadius: '6px' }} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid container spacing={1} justifyContent="center">
                            {renderTimeSlots()}
                        </Grid>
                    )}
                </Box>
                <Typography variant="body2" sx={{ marginTop: '0.2em', textAlign: 'center' }}>
                    {formatSelectedTime(selectedTimeSlots)}
                </Typography>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>Детали резервирования</DialogTitle>
                    <DialogContent
                        sx={{
                            padding: '1em',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5em',
                        }}>
                        {selectedReservations && selectedReservations.length > 0 ? (
                            selectedReservations.map((reservation) => {
                                const user = reservationUsers.find(user => user?.id === reservation.created_by);
                                const room = (selectedRooms?.length ? selectedRooms : Array.isArray(reservationData) ? reservationData : [reservationData])
                                        .find(room => room?.id === reservation.product_id) ||
                                    (Array.isArray(reservationData) ? reservationData.find(data => data.product?.id === reservation.product_id)?.product : reservationData.product);
                                const isPast = dayjs(reservation.data).isBefore(dayjs());
                                return (
                                    <Card
                                        key={reservation.id}
                                        sx={{
                                            cursor: 'pointer',
                                            boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
                                            borderRadius: '12px',
                                            marginTop: '0.2em',
                                        }}
                                    >
                                        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="subtitle1" sx={{ lineHeight: 1, color: isPast ? 'text.secondary' : 'text.primary' }}>
                                                    Мероприятие: {reservation.label || 'Без названия'}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom color={isPast ? 'text.secondary' : 'text.primary'}>
                                                    Зал: {room ? room.label : 'Не указан'}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom color={isPast ? 'text.secondary' : 'text.primary'}>
                                                    Забронировал: {user ? `${user.first_name} ${user.last_name}` : 'Неизвестный пользователь'}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{
                                                borderRadius: '0 0 12px 12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={() => handleIntercept(reservation.id, clickedTimeSlot)}
                                            disabled={isIntercepting}
                                        >
                                            {isIntercepting ? <CircularProgress size={24} color="inherit" /> : 'Перехватить'}
                                        </Button>
                                    </Card>
                                );
                            })
                        ) : (
                            <DialogContentText>Нет информации о мероприятиях.</DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)}>Закрыть</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );

};

export default TimeSlotSelector;

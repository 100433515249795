import React, { useEffect, useState, useCallback, useRef } from "react";
import { ThemeProvider, CircularProgress, Box, Container, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getTheme from '../theme';
import styles from './App.module.css';
import { GlobalStyles } from "@mui/material";
import { setAuthToken } from "../api/api";
import Lottie from 'lottie-react';
import sadAnimation from '../common/lottieGifs/notInTgAnimation.json';
import error401Animation from '../common/lottieGifs/401Animation.json';
import { fetchAllProducts } from "../service/redux/reducers/productSlice";
import { loginUser } from "../service/redux/reducers/authSlice";
import BookingScreen from "./BookingScreen/BookingScreen";
import RoomSelectionScreen from "./RoomSelectionScreen/RoomSelectionScreen";
import RoomReservationScreen from "./RoomReservationScreen/RoomReservationScreen";
import BookingFormScreen from "./BookingFormScreen/BookingFormScreen";
import MyReservationsScreen from "./Booking/MyBooking/MyBooking";
import BottomNavBar from "../components/BottomNavBar/BottomNavBar";
import AdminPanelScreen from "./AdminPanelScreen/AdminPanelScreen";
import AddUserScreen from "./AdminPanelScreen/UsersStack/AddUserScreen/AddUserScreen";
import UsersListScreen from "./AdminPanelScreen/UsersStack/UserListScreen/UserListScreen";
import WhatsNewModal from "./WhatsNewPage/WhatsNewPage";
import MakeReportScreen from "./AdminPanelScreen/MakeReportScreen/MakeReportScreen";
import ProductList from "./AdminPanelScreen/ProductStack/ProductList/ProductList";
import AllBookingScreen from "./Booking/Booking";
import InterceptionListScreen from "./InterceptionScreen/InterceptionListScreen";
import Snowfall from 'react-snowfall';
import AddRoomScreen from "./AdminPanelScreen/ProductStack/AddProductScreen/AddRoomScreen";

const AppContent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [initDataAvailable, setInitDataAvailable] = useState(true);
    const [isTelegramReady, setIsTelegramReady] = useState(false);
    const [isViewportReady, setIsViewportReady] = useState(false);
    const [isAuthError, setIsAuthError] = useState(false);
    const [bottomPadding, setBottomPadding] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const telegramWebApp = window.Telegram.WebApp;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const backButtonRef = useRef(telegramWebApp.BackButton);
    const navigateRef = useRef(navigate);
    const user = useSelector((state) => state.auth.user);
    const currentVersion = "1.8";

    useEffect(() => {
        const lastVersionShown = localStorage.getItem('lastVersionShown');

        if (lastVersionShown !== currentVersion) {
            setIsModalOpen(true);
            localStorage.setItem('lastVersionShown', currentVersion);
        }
    }, [currentVersion]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleBackButtonClick = useCallback(() => {
        if (window.history.length > 1) {
            window.history.back();
        } else {
            navigateRef.current('/');
        }
    }, []);
    const calculateBottomPadding = () => {
        const platform = telegramWebApp.platform;
        if (platform === 'ios') {
            return 3
        } else if (platform === 'android') {
            return 0
        } else {
            return 0;
        }
    };

    useEffect(() => {
        telegramWebApp.ready();
        try {
            if (typeof telegramWebApp.disableVerticalSwipes === 'function') {
                telegramWebApp.disableVerticalSwipes();
            } else {
                console.warn('Метод disableVerticalSwipes не поддерживается.');
            }
        } catch (error) {
            console.error('Ошибка при вызове disableVerticalSwipes:', error);
        }
        const handleViewportChanged = ({ isStateStable }) => {
            if (isStateStable) {
                if (telegramWebApp.viewportStableHeight) {
                    const newPadding = calculateBottomPadding();
                    setBottomPadding(newPadding);
                }
                setIsViewportReady(true);
            }
        };

        telegramWebApp.onEvent('viewportChanged', handleViewportChanged);

        if (telegramWebApp.isExpanded) {
            handleViewportChanged({ isStateStable: true });
        }

        if (telegramWebApp.expand) {
            telegramWebApp.expand();
        }

        backButtonRef.current.onClick(handleBackButtonClick);

        return () => {
            // eslint-disable-next-line
            backButtonRef.current.offClick(handleBackButtonClick);
            telegramWebApp.offEvent('viewportChanged', handleViewportChanged);
        };
        // eslint-disable-next-line
    }, [telegramWebApp, handleBackButtonClick]);

    useEffect(() => {
        const initData = telegramWebApp.initData;
        if (initData) {
            dispatch(loginUser(initData))
                .unwrap()
                .then(response => {
                    const token = response.access_token;
                    setAuthToken(token);
                    dispatch(fetchAllProducts());
                    setIsTelegramReady(true);
                })
                .catch(error => {
                    if (error.code === "ERR_BAD_RESPONSE" || error.response?.status === 401) {
                        setIsAuthError(true);
                    } else {
                        console.error('Error:', error);
                    }
                    setIsTelegramReady(true);
                });
        } else {
            setInitDataAvailable(false);
            setIsTelegramReady(true);
        }
    }, [dispatch, telegramWebApp]);

    useEffect(() => {
        if (location.pathname !== '/') {
            backButtonRef.current.show();
        } else {
            backButtonRef.current.hide();
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isTelegramReady && isViewportReady) {
            setIsLoading(false);
        }
    }, [isTelegramReady, isViewportReady]);

    if (isLoading) {
        return (
            <div className={styles.loaderContainer}>
                <CircularProgress />
            </div>
        );
    }

    if (!initDataAvailable || isAuthError) {
        return (
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    textAlign: 'center'
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <Lottie animationData={isAuthError ? error401Animation : sadAnimation}
                            loop={true} style={{ height: 300, width: 300 }} />
                </Box>
                <Typography variant="body1" className={styles.sadMessage}>
                    {isAuthError
                        ? 'Ошибка авторизации. Проверьте свои данные и попробуйте снова.'
                        : 'Опаньки, похоже это будет работать только в Telegram, или вы не авторизованы в системе.'}
                </Typography>
            </Container>
        );
    }

    return (
        <div className={styles.appContainer}>
            {isModalOpen && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none',
                        zIndex: 1500,
                    }}
                >
                    <Snowfall snowflakeCount={100} />
                </Box>
            )}

            <div className={styles.content}>
                <Routes>
                    <Route path="/" element={<BookingScreen />} />
                    <Route path="/rooms" element={<RoomSelectionScreen />} />
                    <Route path="/rooms/reserve" element={<RoomReservationScreen />} />
                    <Route path="/rooms/booking-form" element={<BookingFormScreen />} />
                    <Route path="/reservation" element={<AllBookingScreen privileges={user} />} />
                    <Route path="/reservation/myreservation" element={<MyReservationsScreen/>} />
                    <Route path="/reservation/interception" element={<InterceptionListScreen/>} />
                    <Route path="/adminpanel" element={<AdminPanelScreen />} />
                    <Route path="/adminpanel/users" element={<UsersListScreen />} />
                    <Route path="/adminpanel/add-user" element={<AddUserScreen />} />
                    <Route path="/adminpanel/products" element={<ProductList />} />
                    <Route path="/adminpanel/add-product" element={<AddRoomScreen />} />
                    <Route path="/adminpanel/report" element={<MakeReportScreen />} />
                </Routes>
            </div>
            <BottomNavBar bottomPadding={bottomPadding} privileges={user?.privileges || []} />
            <WhatsNewModal open={isModalOpen} handleClose={handleCloseModal} currentVersion={currentVersion} />
        </div>
    );
};

const App = () => {
    return (
        <ThemeProvider theme={getTheme('light')}>
            <GlobalStyles styles={{
                body: {
                    backgroundColor: '#FFFFFFFF !important',
                    color: '#1D3557 !important',
                }
            }} />
            <Router>
                <AppContent />
            </Router>
        </ThemeProvider>
    );
};

export default App;

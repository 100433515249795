import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Alert,
} from '@mui/material';
import Lottie from 'lottie-react';
import startBookingGif from "../../common/lottieGifs/startBookingGif.json";
import { fetchInterception } from '../../service/redux/reducers/interceptionSlice';
import AnimatedBox from "../../components/MotionChild/AnimatedBox";
import CloseIcon from '@mui/icons-material/Close';
import { refuseCancelation } from "../../api/api";
import {statusMapping} from "../../utils/statusMapping";

const InterceptionListScreen = () => {
    const dispatch = useDispatch();
    const { interceptionList, loading, error } = useSelector((state) => state.interceptionList);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [selectedInterception, setSelectedInterception] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(fetchInterception());
    }, [dispatch]);

    const handleDeleteClick = (interception) => {
        setSelectedInterception(interception);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            if (selectedInterception?.id) {
                await refuseCancelation(selectedInterception.id);
                setSnackbarMessage('Перехват успешно удален');
                setSnackbarSeverity('success');
                dispatch(fetchInterception());
            }
        } catch (error) {
            setSnackbarMessage('Ошибка при удалении перехвата');
            setSnackbarSeverity('error');
        } finally {
            setOpenSnackbar(true);
            setOpenDialog(false);
            setSelectedInterception(null);
        }
    };

    const handleCloseSnackbar = () => setOpenSnackbar(false);
    const handleCloseDialog = () => setOpenDialog(false);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
            <Typography variant="h6" gutterBottom>Мои запросы на перехват</Typography>
            <AnimatedBox delay={0.2}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                    {interceptionList.length > 0 ? (
                        interceptionList.map((interception) => {
                            const status = statusMapping[interception.status];

                            return (
                                <Card
                                    key={interception.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 1,
                                        p: 1,
                                        boxShadow:
                                            '0px -4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
                                        minHeight: '60px',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', textAlign: 'start', gap: 1 }}>
                                        <CardContent
                                            sx={{
                                                padding: '4px 8px',
                                                '&:last-child': { paddingBottom: '4px' },
                                            }}
                                        >
                                            <Typography variant="body1">
                                                Мероприятие: {interception.reservation.label}
                                            </Typography>
                                            <Typography variant="body1">
                                                Зал: {interception.product.label}
                                            </Typography>
                                            <Typography variant="body1">
                                                Дата: {interception.date}
                                            </Typography>
                                            <Typography variant="body1">
                                                Время: {interception.time}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: status.color,
                                                    fontWeight: 'bold',
                                                    mt: 1,
                                                }}
                                            >
                                                Статус: {status.label}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                    {status.label === "В процессе" && (
                                        <IconButton
                                            onClick={() => handleDeleteClick(interception)}
                                            aria-label="delete interception"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </Card>
                            );
                        })
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', mt: 2 }}>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                У вас нет запросов на перехват
                            </Typography>
                            <Lottie animationData={startBookingGif} style={{ width: 150, height: 150 }} />
                        </Box>
                    )}
                </Box>
            </AnimatedBox>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Подтвердите удаление</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить перехват "{selectedInterception?.reservation.label}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Отмена</Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default InterceptionListScreen;

import React, { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Snackbar,
    Box,
    Alert,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // Импорт useLocation для получения state
import { addUser, updateUser } from "../../../../api/api";
import InputMask from 'react-input-mask';
import { roleEmojis } from "../../../../utils/roleEmojis";
import { useSelector } from "react-redux";
import AnimatedBox from "../../../../components/MotionChild/AnimatedBox";

const AddUserScreen = () => {
    const { group_id } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const { mode = 'add', userData = null } = location.state || {};
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('+7 ');
    const [email, setEmail] = useState('');
    const [privilege, setPrivilege] = useState('admin');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (mode === 'edit' && userData) {
            setFirstName(userData.first_name || '');
            setLastName(userData.last_name || '');
            setPhone(userData.phone || '+7 ');
            setEmail(userData.email || '');
            setPrivilege(userData.privileges[0] || 'admin');
        }
    }, [mode, userData]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const areFieldsValid = () => {
        return (
            firstName.trim() &&
            lastName.trim() &&
            email.trim() &&
            validateEmail(email)
        );
    };

    const handleSubmit = async () => {
        if (!areFieldsValid()) return;

        const userDataToSend = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            privileges: [privilege],
        };

        setIsSubmitting(true);

        try {
            if (mode === 'add') {
                userDataToSend.phone = phone.replace(/\D/g, '').replace(/^8/, '7');
                userDataToSend.groups_ids = [group_id];
                await addUser(userDataToSend);
                setSnackbarMessage('Пользователь успешно добавлен');
            } else if (mode === 'edit' && userData) {
                await updateUser(userData.id, userDataToSend);
                setSnackbarMessage('Пользователь успешно обновлён');
            }
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setTimeout(() => navigate('/adminpanel/users'), 2000);
        } catch (error) {
            console.error('Error during user operation:', error);
            setSnackbarMessage('Ошибка при обработке пользователя');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <AnimatedBox delay={0.1}>
                <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
                    <Typography variant="h6" gutterBottom>
                        {mode === 'add' ? 'Добавить пользователя' : 'Редактировать пользователя'}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
                        <TextField
                            label="Имя"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            fullWidth
                            variant="outlined"
                            required
                        />
                        <TextField
                            label="Фамилия"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            fullWidth
                            variant="outlined"
                            required
                        />
                        {mode === 'add' && (
                            <InputMask
                                mask="+7 999 999 99 99"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                maskPlaceholder=" "
                                required
                            >
                                {() => (
                                    <TextField
                                        label="Телефон"
                                        fullWidth
                                        variant="outlined"
                                        required
                                    />
                                )}
                            </InputMask>
                        )}
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            variant="outlined"
                            required
                        />
                        <TextField
                            label="Роль пользователя"
                            value={privilege}
                            onChange={(e) => setPrivilege(e.target.value)}
                            fullWidth
                            select
                            variant="outlined"
                        >
                            {Object.entries(roleEmojis)
                                .filter(([key]) => key !== 'global_admin')
                                .map(([key, { label, emoji }]) => (
                                    <MenuItem key={key} value={key}>
                                        {emoji} {label}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                            disabled={!areFieldsValid() || isSubmitting}
                            sx={{ mt: 2 }}
                        >
                            {mode === 'add' ? 'Добавить пользователя' : 'Сохранить изменения'}
                        </Button>
                    </Box>
                </Container>
            </AnimatedBox>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AddUserScreen;

import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    MenuItem,
    Select,
    Typography,
    Snackbar,
    Alert,
    Modal
} from '@mui/material';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AnimatedBox from "../../../components/MotionChild/AnimatedBox";
import { getReport } from "../../../api/api";

const ReportDownloadScreen = () => {
    const [reportPeriod, setReportPeriod] = useState('');
    const [customPeriod, setCustomPeriod] = useState({ startDate: null, endDate: null });
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const periods = {
        today: 0,
        '3days': 3,
        '10days': 10,
        '30days': 30,
        '666days': 666,
        custom: 'custom',
    };

    const resetToDefault = () => {
        setReportPeriod('today');
        setCustomPeriod({ startDate: null, endDate: null });
    };

    const handlePeriodChange = (event) => {
        const value = event.target.value;
        setReportPeriod(value);
        if (value === 'custom') {
            setCalendarOpen(true);
        }
    };

    const handleDownloadReport = async () => {
        if (reportPeriod) {
            let reportParams = {};
            if (reportPeriod === 'custom') {
                if (customPeriod.startDate && customPeriod.endDate) {
                    reportParams = {
                        start_date: customPeriod.startDate.format('YYYY-MM-DD'),
                        end_date: customPeriod.endDate.format('YYYY-MM-DD'),
                    };
                } else {
                    setSnackbarMessage('Выберите начальную и конечную дату');
                    setSnackbarSeverity('warning');
                    setSnackbarOpen(true);
                    resetToDefault();
                    return;
                }
            } else {
                reportParams = { period_days: periods[reportPeriod] };
            }

            try {
                const response = await getReport(reportParams);

                if (response?.data?.success) {
                    const fileLinkToOpen = response.data.data;

                    if (window.Telegram && window.Telegram.WebApp) {
                        window.Telegram.WebApp.openLink(fileLinkToOpen);
                    } else {
                        window.location.href = fileLinkToOpen;
                    }

                    setSnackbarMessage('Отчет успешно загружен!');
                    setSnackbarSeverity('success');
                } else {
                    setSnackbarMessage(response.data?.error?.message || 'Неизвестная ошибка');
                    setSnackbarSeverity('error');
                }
            } catch (error) {
                if (error.data.error?.code === 4103) {
                    setSnackbarMessage('За выбранный период нет бронирований');
                    setSnackbarSeverity('info');
                } else if (error.response?.data?.error?.message) {
                    setSnackbarMessage(error.response.data.error.message);
                    setSnackbarSeverity('error');
                } else {
                    setSnackbarMessage('Ошибка при загрузке отчета');
                    setSnackbarSeverity('error');
                }
            } finally {
                setSnackbarOpen(true);
                resetToDefault();
            }
        } else {
            setSnackbarMessage('Пожалуйста, выберите период отчета');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            resetToDefault();
        }
    };

    const handleCalendarClose = () => {
        setCalendarOpen(false);
    };

    const handleDateChange = (date) => {
        if (!customPeriod.startDate || (customPeriod.startDate && customPeriod.endDate)) {
            setCustomPeriod({ startDate: date, endDate: null });
        } else {
            setCustomPeriod((prev) => ({ ...prev, endDate: date }));
        }
    };

    const isSaveDisabled = !customPeriod.startDate || !customPeriod.endDate;

    return (
        <>
            <AnimatedBox delay={0.1}>
                <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <Typography variant="body1">Выберите период для отчета:</Typography>
                        <Select
                            value={reportPeriod}
                            onChange={handlePeriodChange}
                            displayEmpty
                            fullWidth
                            sx={{ maxWidth: '300px' }}
                        >
                            <MenuItem value="" disabled>Выберите период</MenuItem>
                            <MenuItem value="today">Сегодня</MenuItem>
                            <MenuItem value="3days">За последние 3 дня</MenuItem>
                            <MenuItem value="10days">За последние 10 дней</MenuItem>
                            <MenuItem value="30days">За последние 30 дней</MenuItem>
                            <MenuItem value="666days">За весь период</MenuItem>
                            <MenuItem value="custom">Произвольный период</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownloadReport}
                            sx={{ maxWidth: '300px', mt: 2 }}
                        >
                            Скачать отчет
                        </Button>
                    </Box>
                </Container>
            </AnimatedBox>
            <Modal open={calendarOpen} onClose={handleCalendarClose}>
                <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', maxWidth: '400px', borderRadius: '8px' }}>
                    <Typography variant="h6" gutterBottom>
                        {!customPeriod.startDate ? 'Выберите начальную дату' : 'Выберите конечную дату'}
                    </Typography>
                    {customPeriod.startDate && (
                        <Typography variant="body2" gutterBottom>
                            Начальная дата: {customPeriod.startDate.format('DD.MM.YYYY')}
                        </Typography>
                    )}
                    {customPeriod.endDate && (
                        <Typography variant="body2" gutterBottom>
                            Конечная дата: {customPeriod.endDate.format('DD.MM.YYYY')}
                        </Typography>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                        <DateCalendar
                            value={customPeriod.endDate || customPeriod.startDate}
                            onChange={handleDateChange}
                            minDate={customPeriod.startDate || null}
                            sx={{
                                height: 'auto !important',
                                minHeight: 'auto !important',
                                width: '100% !important',
                                '& .MuiPickersCalendarHeader-label': {
                                    color: 'black !important',
                                },
                                '& .MuiPickersCalendarHeader-root': {
                                    width: '100% !important',
                                    padding: '0 !important',
                                    marginTop: '0 !important',
                                },
                                '& .MuiDayCalendar-root': {
                                    width: '100% !important',
                                    margin: '0 !important',
                                },
                                '& .MuiDayCalendar-weekContainer': {
                                    margin: '0 !important',
                                    padding: '0 !important',
                                },
                                '& .MuiPickersSlideTransition-root': {
                                    minHeight: '230px !important',
                                    height: '230px !important',
                                },
                                '& .MuiDayCalendar-monthContainer': {
                                    alignItems: 'center',
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCalendarClose}
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={isSaveDisabled}
                    >
                        Сохранить
                    </Button>
                </Box>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ReportDownloadScreen;

import React from 'react';
import { Box, Typography, Button, Card, CardContent, CardMedia, Link } from '@mui/material';
import AnimatedBox from '../../components/MotionChild/AnimatedBox';
import { useLocation, useNavigate } from 'react-router-dom';
const RoomSelectionScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { category, data } = location.state || {};

    if (!data || !category) {
        return (
            <Box sx={{ padding: '0.2em' }}>
                <Typography variant="h6" gutterBottom>
                    Ошибка: Данные не найдены
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                    Вернуться на главную
                </Button>
            </Box>
        );
    }

    const handleReserveRoom = (item) => {
        navigate("/rooms/reserve", { state: { selectedRoom: item } });
    };

    const handleSelectAnyRoom = () => {
        navigate("/rooms/reserve", { state: { selectedRoom: data } });
    };

    return (
        <Box sx={{ padding: '0.2em' }}>
            <AnimatedBox delay={0.1}>
                <Card
                    sx={{
                        marginBottom: '1em',
                        borderRadius: '16px',
                        border: '2px solid #e0e0e0',
                        background: 'linear-gradient(135deg, #ffffff, #f7f7f7)',
                    }}
                    onClick={handleSelectAnyRoom}
                >
                    <CardContent sx={{ textAlign: 'center' }}>
                        <Typography variant="h6">Любое помещение</Typography>
                    </CardContent>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={(event) => {
                            event.stopPropagation();
                            handleSelectAnyRoom();
                        }}
                        sx={{
                            borderRadius: '0 0 12px 12px',
                        }}
                    >
                        Выбрать любое помещение
                    </Button>
                </Card>
            </AnimatedBox>

            {data.map((item) => (
                <AnimatedBox delay={0.2} key={item.id}>
                    <Card sx={{ marginBottom: '1em', borderRadius: '16px' }}
                          onClick={() => handleReserveRoom(item)}>
                        <CardMedia
                            component="img"
                            image={item.image_link}
                            alt={item.label}
                            sx={{ width: '100%', height: 'auto',maxHeight:'300px', borderRadius: '12px 12px 0 0' }}
                        />
                        <CardContent>
                            <Typography variant="h6">{item.label}</Typography>
                            {item.additionally?.address && (
                                <Typography variant="body2">
                                    Адрес: <Link
                                    onClick={(event) => event.stopPropagation()}
                                    href={`https://yandex.ru/maps/?rtext=~${item.additionally?.coordinates}&rtt=auto`} target="_blank" rel="noopener noreferrer">
                                    {item.additionally.address}
                                </Link>
                                </Typography>
                            )}
                            {category === 'car' ? (
                                item.additionally?.mark && (
                                    <Typography variant="body2">
                                        Марка: {item.additionally.mark}
                                    </Typography>
                                )
                            ) : (
                                <>
                                    {item.additionally?.capacity && (
                                        <Typography variant="body2">
                                            Вместимость: {item.additionally.capacity}
                                        </Typography>
                                    )}
                                    {item.additionally?.opening_hours && (
                                        <Typography variant="body2">
                                            Время работы: {item.additionally.opening_hours}
                                        </Typography>
                                    )}
                                    {item.description && (
                                        <Typography variant="body2">
                                            Дополнительно: {item.description}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </CardContent>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={(event) => {
                                event.stopPropagation();
                                handleReserveRoom(item)
                            }}
                            sx={{
                                borderRadius: '0 0 12px 12px',
                            }}
                        >
                            Выбрать {item.label}
                        </Button>
                    </Card>
                </AnimatedBox>
            ))}
        </Box>
    );
};

export default RoomSelectionScreen;

import React, { useState } from 'react';
import { Container, Box, Button, Typography, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import addRoomGif from '../../common/lottieGifs/Animation - 1730041044364.json';
import addUserGif from '../../common/lottieGifs/Animation - 1730041047280.json';
import reportD from '../../common/lottieGifs/Report.json';
import AnimatedBox from "../../components/MotionChild/AnimatedBox";

const AdminPanelScreen = () => {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
            <Typography variant="h6" gutterBottom>Панель Администратора</Typography>
            <AnimatedBox delay={0.1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 3 }}>
                    <Box
                        sx={{
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Lottie animationData={addUserGif} loop style={{ height: 150, width: '100%' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ borderRadius: '0 0 12px 12px' }}
                            onClick={() => navigate('/adminpanel/users')}
                        >
                            Управление пользователями
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Lottie animationData={addRoomGif} loop style={{ height: 150, width: '100%' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ borderRadius: '0 0 12px 12px' }}
                            onClick={() => navigate('/adminpanel/products')}
                        >
                            Управление помещениями
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Lottie animationData={reportD} loop style={{ height: 150, width: '100%' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ borderRadius: '0 0 12px 12px' }}
                            onClick={() => navigate('/adminpanel/report')}
                        >
                            Скачать отчет
                        </Button>
                    </Box>
                </Box>
            </AnimatedBox>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2500}
                onClose={handleSnackbarClose}
                message="Данный раздел находится в разработке"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            />
        </Container>
    );
};

export default AdminPanelScreen;

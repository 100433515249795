import React from 'react';
import { Container, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import bookingGif from '../../common/lottieGifs/Booking.json';
import addUserGif from '../../common/lottieGifs/Interception.json';
import allBookingsGif from '../../common/lottieGifs/BookingAll.json';
import AnimatedBox from "../../components/MotionChild/AnimatedBox";

const AllBookingScreen = (props) => {
    const { privileges } = props;
    const navigate = useNavigate();
    const privilegeArray = privileges.privileges || [];
    const hasAdminPrivileges =
        privilegeArray.includes('admin') || privilegeArray.includes('global_admin');

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
            <Typography variant="h6" gutterBottom>Бронирования</Typography>
            <AnimatedBox delay={0.1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 3 }}>
                    <Box
                        sx={{
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Lottie animationData={bookingGif} loop style={{ height: 150, width: '100%' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ borderRadius: '0 0 12px 12px' }}
                            onClick={() => navigate('/reservation/myreservation', { state: { privileges, isMy: true } })}
                        >
                            Мои бронирования
                        </Button>
                    </Box>
                    {hasAdminPrivileges && (
                        <Box
                            sx={{
                                borderRadius: '16px',
                                overflow: 'hidden',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Lottie animationData={allBookingsGif} loop style={{ height: 150, width: '100%' }} />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ borderRadius: '0 0 12px 12px' }}
                                onClick={() => navigate('/reservation/myreservation', { state: { privileges, isMy: false } })}
                            >
                                Все бронирования
                            </Button>
                        </Box>
                    )}
                    <Box
                        sx={{
                            borderRadius: '16px',
                            overflow: 'hidden',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Lottie animationData={addUserGif} loop style={{ height: 150, width: '100%' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ borderRadius: '0 0 12px 12px' }}
                            onClick={() => navigate('/reservation/interception')}
                        >
                            Запросы на перехват
                        </Button>
                    </Box>
                </Box>
            </AnimatedBox>
        </Container>
    );
};

export default AllBookingScreen;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getUserInterception} from "../../../api/api";

export const fetchInterception = createAsyncThunk(
    'interception/fetchInterception',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const data = await getUserInterception();
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching user list');
        }
    }
);

const interceptionSlice = createSlice({
    name: 'interception',
    initialState: {
        interceptionList: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInterception.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchInterception.fulfilled, (state, action) => {
                state.loading = false;
                state.interceptionList = action.payload;
            })
            .addCase(fetchInterception.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default interceptionSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Alert, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchUserList } from "../../../../service/redux/reducers/userListSlice";
import { roleEmojis } from "../../../../utils/roleEmojis";
import { useNavigate } from 'react-router-dom';
import { deleteUser } from "../../../../api/api";
import AnimatedBox from "../../../../components/MotionChild/AnimatedBox";

const UsersListScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userList, loading, error } = useSelector((state) => state.usersList);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(fetchUserList());
    }, [dispatch]);

    const handleDeleteClick = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            if (selectedUser) {
                await deleteUser(selectedUser.id);
                setSnackbarMessage('Пользователь успешно удален');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                dispatch(fetchUserList());
            }
        } catch (error) {
            console.error('Failed to delete user:', error);
            setSnackbarMessage('Ой, что-то пошло не так');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setOpenDialog(false);
            setSelectedUser(null);
        }
    };

    const handleCloseSnackbar = () => setOpenSnackbar(false);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleEditClick = (user) => {
        navigate('/adminpanel/add-user', { state: { mode: 'edit', userData: user } });
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <>
            <AnimatedBox delay={0.1}>
                <Container maxWidth="sm">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6" gutterBottom>Пользователи</Typography>
                        <IconButton onClick={() => navigate('/adminpanel/add-user')} aria-label="add user">
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                        {userList.map((user) => (
                            <Card
                                key={user.id}
                                onClick={() => handleEditClick(user)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: 1,
                                    p: 1,
                                    cursor: 'pointer',
                                    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
                                    minHeight: '60px'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="h5">
                                        {roleEmojis[user.privileges[0]]?.emoji || '👤'}
                                    </Typography>
                                    <CardContent sx={{ padding: '4px 8px', '&:last-child': { paddingBottom: '4px' } }}>
                                        <Typography variant="body1">
                                            {`${user.first_name || ''} ${user.last_name || ''}`.trim() || 'Имя не указано'}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {roleEmojis[user.privileges[0]]?.label || 'Роль не указана'}
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteClick(user);
                                }} aria-label="delete user">
                                    <DeleteIcon />
                                </IconButton>
                            </Card>
                        ))}
                    </Box>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Подтвердите удаление</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Вы уверены, что хотите удалить пользователя {selectedUser?.first_name} {selectedUser?.last_name}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Отмена</Button>
                            <Button onClick={handleDeleteConfirm} color="error" autoFocus>Удалить</Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </AnimatedBox>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default UsersListScreen;

import { createTheme } from '@mui/material/styles';

const getTheme = (themeMode) => createTheme({
    palette: {
        mode: themeMode,
        primary: {
            main: '#1D3557',
            contrastText: '#fff',
        },
        secondary: {
            main: '#E63946',
            contrastText: '#fff',
        },
        background: {
            default: themeMode === 'dark' ? '#ffffff' : '#ffffff',
            paper: themeMode === 'dark' ? '#ffffff' : '#ffffff',
        },
        text: {
            primary: '#1D3557',
            secondary: '#457B9D',
        },
    },
    typography: {
        fontFamily: 'Poppins, Arial, sans-serif',
        h6: {
            fontWeight: 600,
            color: '#F1FAEE',
        },
        h7: {
            fontWeight: 450,
        },
        button: {
            fontWeight: 500,
            color: '#F1FAEE',
        },
        body1: {
            color: themeMode === 'dark' ? '#ffffff' : '#ffffff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    textTransform: 'none',
                    padding: '10px 20px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: '0.2em',
                },
                body1: {
                    color: '#1D3557',
                },
                h6: {
                    color: '#1D3557',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1D3557',
                    boxShadow: 'none',
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                    boxShadow: themeMode === 'dark'
                        ? '0px -2px 10px rgba(0, 0, 0, 0.7)'
                        : '0px -2px 10px rgba(0, 0, 0, 0.1)',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#1D3557',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    '& .MuiInputBase-root': {
                        color: '#1D3557',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#457B9D',
                        borderRadius: 16,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1D3557',
                        borderRadius: 16,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1D3557',
                        borderRadius: 16,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#1D3557',
                    '&.Mui-focused': {
                        color: '#1D3557',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#1D3557',
                    backgroundColor: '#ffffff',
                    paddingLeft: '16px',
                    '&:hover': {
                        color: '#1D3557',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#457B9D',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#1D3557',
                        },
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    '& .MuiAutocomplete-option': {
                        color: '#1D3557',
                        '&[aria-selected="true"]': {
                            backgroundColor: '#457B9D',
                            color: '#ffffff',
                        },
                        '&.Mui-focused': {
                            backgroundColor: '#1D3557',
                            color: '#ffffff',
                        },
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1D3557',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1D3557',
                    },
                    '& .MuiSelect-icon': {
                        color: '#1D3557',
                    },
                },
            },
        },
    },
});

export default getTheme;

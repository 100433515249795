import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    Snackbar,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import AnimatedBox from '../../../../components/MotionChild/AnimatedBox';
import MapModal from '../../../../components/MapModal/MapModal';
import { addProduct } from '../../../../api/api';
import { useNavigate } from 'react-router-dom';
import { uploadRoomImage } from '../../../../api/api';
import {fetchAllProducts} from "../../../../service/redux/reducers/productSlice";

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        ['00', '30'].forEach((minute) => {
            const formattedHour = hour.toString().padStart(2, '0');
            times.push(`${formattedHour}:${minute}`);
        });
    }
    return times;
};

const TIME_OPTIONS = generateTimeOptions();

const AddRoomScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [capacity, setCapacity] = useState('');
    const [openingHoursStart, setOpeningHoursStart] = useState('');
    const [openingHoursEnd, setOpeningHoursEnd] = useState('');
    const [coordinates, setCoordinates] = useState('55.751244,37.618423');
    const [address, setAddress] = useState('');
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageLink, setImageLink] = useState('');
    const { group_id } = useSelector((state) => state.auth);

    const handleMapModalOpen = () => {
        setIsMapModalOpen(true);
    };

    const handleMapModalClose = (newAddressData) => {
        setIsMapModalOpen(false);
        if (newAddressData) {
            setAddress(newAddressData.address);
            setCoordinates(newAddressData.coordinates);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/') && file.size <= 10 * 1024 * 1024) {
            setSelectedFile(file);
            try {
                const link = await uploadRoomImage(file, (progressEvent) => {
                    console.log(
                        `Upload progress: ${Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )}%`
                    );
                });
                setImageLink(link);
                alert('Файл успешно загружен!');
            } catch (error) {
                alert(error.message || 'Файл не загружен! Попробуйте еще раз');
            }
        } else {
            alert('Ваш файл превышает допустимый размер (не более 10MB).');
        }
    };

    const handleSave = async () => {
        const newRoom = {
            group_id: group_id,
            label,
            description,
            type: 'talk_room',
            image_link: imageLink,
            additionally: {
                capacity: Number(capacity),
                opening_hours: `C ${openingHoursStart} до ${openingHoursEnd}`,
                opening_hours_start: openingHoursStart,
                opening_hours_end: openingHoursEnd,
                coordinates,
                address,
            },
        };

        setIsSubmitting(true);

        try {
            await addProduct(newRoom);
            setSnackbarOpen(true);
            dispatch(fetchAllProducts());
            setTimeout(() => {
                navigate('/adminpanel/products');
            }, 1500);
        } catch (error) {
            console.error('Error adding room:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <AnimatedBox delay={0.1}>
            <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
                <Typography variant="h6" gutterBottom>
                    Добавить помещение
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
                    <TextField
                        label="Название"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        fullWidth
                        variant="outlined"
                        required
                    />
                    <TextField
                        label="Описание"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        label="Вместимость"
                        value={capacity}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setCapacity(value);
                            }
                        }}
                        fullWidth
                        variant="outlined"
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="start-time-label">Начало работы</InputLabel>
                            <Select
                                labelId="start-time-label"
                                value={openingHoursStart}
                                label="Начало работы"
                                onChange={(e) => setOpeningHoursStart(e.target.value)}
                            >
                                {TIME_OPTIONS.map((time) => (
                                    <MenuItem key={time} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="end-time-label">Окончание работы</InputLabel>
                            <Select
                                labelId="end-time-label"
                                value={openingHoursEnd}
                                label="Окончание работы"
                                onChange={(e) => setOpeningHoursEnd(e.target.value)}
                            >
                                {TIME_OPTIONS.map((time) => (
                                    <MenuItem key={time} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleMapModalOpen}
                    >
                        Указать местоположение
                    </Button>
                    {address && (
                        <Typography variant="body2" color="text.secondary">
                            Адрес: {address}
                        </Typography>
                    )}
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="image-upload-input"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="image-upload-input" style={{ width: '100%' }}>
                        <Button variant="outlined" component="span" fullWidth>
                            Выбрать изображение
                        </Button>
                    </label>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                    >
                        (Рекомендуемый формат 4х3)
                    </Typography>

                    {selectedFile && (
                        <Typography variant="body2" color="text.secondary">
                            {selectedFile.name} ({(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSave}
                        disabled={!label || isSubmitting || !imageLink}
                    >
                        Добавить помещение
                    </Button>
                </Box>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={1500}
                    onClose={() => setSnackbarOpen(false)}
                    message="Помещение успешно добавлено!"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                {isMapModalOpen && (
                    <MapModal
                        open={isMapModalOpen}
                        onClose={handleMapModalClose}
                        currentCoordinates={coordinates}
                        currentAddress={address}
                    />
                )}
            </Container>
        </AnimatedBox>
    );
};

export default AddRoomScreen;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getDeputies} from "../../../api/api";

export const fetchDeputies = createAsyncThunk(
    'deputiesSlice/fetchDeputies',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const data = await getDeputies();
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching user list');
        }
    }
);

const deputiesSlice = createSlice({
    name: 'deputies',
    initialState: {
        deputiesList: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeputies.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDeputies.fulfilled, (state, action) => {
                state.loading = false;
                state.deputiesList = action.payload;
            })
            .addCase(fetchDeputies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default deputiesSlice.reducer;

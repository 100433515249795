import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Container,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    DialogContent,
    DialogTitle,
    Dialog,
    Button,
    IconButton,
    DialogActions,
    Snackbar
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from "../../../utils/formatDate";
import { fetchUserReservations } from "../../../service/redux/reducers/myReservationSlice";
import CloseIcon from '@mui/icons-material/Close';
import DeleteButton from "../../../components/Common/DeleteButton/DeleteButton";
import { deleteReservation } from "../../../api/api";
import AnimatedBox from "../../../components/MotionChild/AnimatedBox";
import {useLocation, useNavigate} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import startBookingGif from "../../../common/lottieGifs/startBookingGif.json";
import Lottie from "lottie-react";
import { fetchUserList } from "../../../service/redux/reducers/userListSlice";
import { roleEmojis } from "../../../utils/roleEmojis";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const MyReservationsScreen = () => {
    const location = useLocation();
    const privileges = location.state?.privileges;
    const dispatch = useDispatch();
    const { reservations, loading, error } = useSelector((state) => state.myReservations);
    const [expandedReservation, setExpandedReservation] = useState(null);
    const [futureReservations, setFutureReservations] = useState([]);
    const [pastReservations, setPastReservations] = useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [reservationToDelete, setReservationToDelete] = useState(null);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { userList } = useSelector((state) => state.usersList);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [fileLinkToOpen, setFileLinkToOpen] = useState('');
    const [fileNameToOpen, setFileNameToOpen] = useState('');
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        dispatch(fetchUserReservations());
        dispatch(fetchUserList());
    }, [dispatch]);

    useEffect(() => {
        if (reservations) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const now = new Date();

            const future = [];
            const past = [];

            const filteredReservations = location.state?.isMy
                ? reservations.filter(reservation => reservation.created_by === user.id)
                : reservations;

            filteredReservations.forEach(reservation => {
                const reservationDate = new Date(reservation.data);
                reservationDate.setHours(0, 0, 0, 0);

                if (reservationDate > today) {
                    future.push(reservation);
                } else if (reservationDate < today) {
                    past.push(reservation);
                } else {
                    const endTimes = reservation.reservation_time.map(time => time.split('-')[1]);
                    const latestEndTimeStr = endTimes[endTimes.length - 1];
                    const [endHour, endMinute] = latestEndTimeStr.split(':').map(Number);
                    const reservationEndDateTime = new Date(reservationDate);
                    reservationEndDateTime.setHours(endHour, endMinute, 0, 0);

                    if (now > reservationEndDateTime) {
                        past.push(reservation);
                    } else {
                        future.push(reservation);
                    }
                }
            });

            future.sort((a, b) => {
                const dateA = new Date(a.data);
                const dateB = new Date(b.data);

                if (dateA - dateB !== 0) {
                    return dateA - dateB;
                } else {
                    const timeA = a.reservation_time[0].split('-')[0];
                    const timeB = b.reservation_time[0].split('-')[0];
                    return timeA.localeCompare(timeB);
                }
            });

            past.sort((a, b) => {
                const dateA = new Date(a.data);
                const dateB = new Date(b.data);

                if (dateA - dateB !== 0) {
                    return dateB - dateA;
                } else {
                    const timeA = a.reservation_time[0].split('-')[0];
                    const timeB = b.reservation_time[0].split('-')[0];
                    return timeB.localeCompare(timeA);
                }
            });

            setFutureReservations(future);
            setPastReservations(past);
        }
    }, [reservations, location.state?.isMy, user.id]);

    const handleOpenReservation = (reservation) => {
        setExpandedReservation(reservation);
    };

    const handleCloseReservation = () => {
        setExpandedReservation(null);
    };

    const handleDeleteClick = (id) => {
        setReservationToDelete(id);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteReservation(reservationToDelete);
            dispatch(fetchUserReservations());
            setConfirmDeleteOpen(false);
            handleCloseReservation();
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Ошибка при удалении резервации:', error);
        }
    };

    const handleCancelDelete = () => {
        setConfirmDeleteOpen(false);
        setReservationToDelete(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleNavigateToBooking = () => {
        navigate('/');
    };

    const groupReservationTimes = (times) => {
        if (!times || times.length === 0) return [];

        const groupedTimes = [];
        let startTime = times[0].split('-')[0];
        let endTime = times[0].split('-')[1];

        for (let i = 1; i < times.length; i++) {
            const [nextStart, nextEnd] = times[i].split('-');
            if (nextStart === endTime) {
                endTime = nextEnd;
            } else {
                groupedTimes.push(`${startTime}-${endTime}`);
                startTime = nextStart;
                endTime = nextEnd;
            }
        }
        groupedTimes.push(`${startTime}-${endTime}`);

        return groupedTimes;
    };

    const handleMergeTime = (reservation) => {
        navigate('/rooms/reserve', {
            state: { reservation, isEditing: true }
        });
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Container>
        );
    }

    const renderReservationCard = (reservation, isPast) => (
        <Card
            key={reservation.id}
            onClick={() => handleOpenReservation(reservation)}
            sx={{
                cursor: 'pointer',
                marginBottom: 2,
                boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)',
                backgroundColor: isPast ? '#f0f0f0' : 'white',
                pointerEvents: 'auto',
            }}
        >
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '0 !important' }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ lineHeight: 1, color: isPast ? 'text.secondary' : 'text.primary' }}>
                        {reservation.label}
                    </Typography>
                    <Typography variant="body2" color={isPast ? 'text.secondary' : 'text.primary'}>
                        {`Дата: ${formatDate(reservation.data)}`}
                    </Typography>
                    {groupReservationTimes(reservation.reservation_time).map((groupedTime, index) => (
                        <Typography key={index} variant="body2" color={isPast ? 'text.secondary' : 'text.primary'}>
                            {`Время: ${groupedTime}`}
                        </Typography>
                    ))}
                    <Typography variant="body2" color={isPast ? 'text.secondary' : 'text.primary'}>
                        {`${reservation.product.type === 'talk_room' ? 'Помещение' : reservation.product.type === 'car' ? 'Автомобиль' : 'Продукт'}: ${reservation.product.label}`}
                    </Typography>
                    <Typography variant="body2" color="primary" sx={{ marginBottom: 0.5, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleOpenReservation(reservation)}>
                        Подробнее
                    </Typography>
                </Box>
                <Box sx={{ marginLeft: 2 }}>
                    <img
                        src={reservation.product.image_link}
                        alt={reservation.product.label}
                        style={{ width: 100, height: 100, borderRadius: 8, objectFit: 'cover' }}
                    />
                </Box>
            </CardContent>
        </Card>
    );

    const formatFileSize = (size) => {
        if (size < 1024) return `${size} B`;
        else if (size >= 1024 && size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
        else if (size >= 1048576) return `${(size / 1048576).toFixed(2)} MB`;
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return <InsertDriveFileIcon color="error" />;
            case 'doc':
            case 'docx':
                return <InsertDriveFileIcon color="primary" />;
            case 'xls':
            case 'xlsx':
                return <InsertDriveFileIcon color="success" />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <InsertDriveFileIcon color="secondary" />;
            default:
                return <InsertDriveFileIcon />;
        }
    };

    const handleFileClick = (link, fileName) => {
        setFileLinkToOpen(link);
        setFileNameToOpen(fileName);
        setOpenConfirmDialog(true);
    };

    const handleConfirmOpen = () => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(fileLinkToOpen);
        } else {
            window.location.href = fileLinkToOpen;
        }
        setOpenConfirmDialog(false);
        setFileLinkToOpen('');
    };

    const handleCancelOpen = () => {
        setOpenConfirmDialog(false);
        setFileLinkToOpen('');
    };

    const createdByUser = userList.find(user => user.id === expandedReservation?.created_by);
    const hasAdminPrivileges = Array.isArray(privileges?.privileges) &&
        (privileges.privileges.includes('admin') || privileges.privileges.includes('global_admin'));

    return (
        <>
            <AnimatedBox delay={0.1}>
                <Container sx={{ padding: 0 }}>
                    <Box>
                        {futureReservations.length === 0 ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', mt: 2 }}>
                                <Typography variant="body1" sx={{ mb: 2}}>
                                    У вас нет будущих бронирований
                                </Typography>
                                <Lottie animationData={startBookingGif} style={{ width: 150, height: 150 }} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: '12px', mt: 2 }}
                                    onClick={handleNavigateToBooking}
                                >
                                    Начать бронирование
                                </Button>
                            </Box>
                        ) : (
                            <List sx={{ paddingLeft: '4px', paddingRight: '4px' }}>
                                {futureReservations.map(reservation => renderReservationCard(reservation, false))}
                            </List>
                        )}
                    </Box>

                    <Box>
                        {pastReservations.length === 0 ? (
                            <Typography variant="body1" sx={{textAlign: 'center'}}>
                                У вас нет прошедших бронирований
                            </Typography>
                        ) : (
                            <List sx={{ paddingLeft: '4px', paddingRight: '4px' }}>
                                {pastReservations.map(reservation => renderReservationCard(reservation, true))}
                            </List>
                        )}
                    </Box>

                    {expandedReservation && (
                        <Dialog open={true} onClose={handleCloseReservation} fullWidth maxWidth="sm">
                            <DialogTitle>
                                {expandedReservation.label}
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseReservation}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body2">
                                    {`Дата: ${formatDate(expandedReservation.data)}`}
                                </Typography>
                                {groupReservationTimes(expandedReservation.reservation_time).map((groupedTime, index) => (
                                    <Typography key={index} variant="body2">
                                        {`Время: ${groupedTime}`}
                                    </Typography>
                                ))}
                                <Typography variant="body2">
                                    {`${expandedReservation.type === 'talk_room' ? 'Помещение' : expandedReservation.product.type === 'car' ? 'Автомобиль' : 'Продукт'}: ${expandedReservation.product.label}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`Адрес: ${expandedReservation.product.additionally?.address}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`Вместимость: ${expandedReservation.product.additionally?.capacity}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`Часы работы: ${expandedReservation.product.additionally?.opening_hours}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`Заместитель: ${expandedReservation.depute}`}
                                </Typography>
                                {hasAdminPrivileges && createdByUser && (
                                    <Box sx={{ marginTop: 2 }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            Создатель бронирования:
                                        </Typography>
                                        <Typography variant="body2">
                                            {`${createdByUser.first_name} ${createdByUser.last_name}`}
                                        </Typography>
                                        <Typography variant="body2">
                                            {`Email: ${createdByUser.email}`}
                                        </Typography>
                                    </Box>
                                )}
                                <Typography variant="body2" sx={{ marginTop: 2 }}>
                                    Ответственные:
                                </Typography>
                                <List sx={{ padding: '0 !important', maxHeight: 130, overflow: 'auto' }}>
                                    {expandedReservation.maintenance_ids.map((id) => {
                                        const responsibleUser = userList.find((user) => user.id === id);
                                        return responsibleUser ? (
                                            <ListItem key={id} sx={{ padding: '4px 0' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
                                                    {roleEmojis[responsibleUser.privileges[0]]?.emoji || '👤'}
                                                </Typography>
                                                <ListItemText
                                                    primary={`${responsibleUser.first_name} ${responsibleUser.last_name}`}
                                                    secondary={roleEmojis[responsibleUser.privileges[0]]?.label || 'Роль не указана'}
                                                />
                                            </ListItem>
                                        ) : null;
                                    })}
                                </List>

                                <Typography variant="body2">
                                    {`Кол-во Участников: ${expandedReservation.count_participants}`}
                                </Typography>

                                <Typography variant="body2" sx={{ marginTop: 2 }}>
                                    Участники:
                                </Typography>
                                <List sx={{ padding: '0 !important', maxHeight: 130, overflow: 'auto' }}>
                                    {expandedReservation.emails.map((email, index) => (
                                        <ListItem sx={{ padding: '0 !important' }} key={index}>
                                            <ListItemText primary={email} />
                                        </ListItem>
                                    ))}
                                </List>

                                {expandedReservation.files && expandedReservation.files.length > 0 && (
                                    <>
                                        <Typography variant="body2" sx={{ marginTop: 2 }}>
                                            Файлы:
                                        </Typography>
                                        <List sx={{ padding: '0 !important' }}>
                                            {expandedReservation.files.map((file) => (
                                                <ListItem
                                                    key={file.id}
                                                    sx={{
                                                        padding: '8px 0',
                                                        cursor: 'pointer',
                                                        borderBottom: '1px solid #e0e0e0',
                                                    }}
                                                    handleFileClick
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleFileClick(file.link, file.file_name);
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        {getFileIcon(file.file_name)}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                {file.file_name}
                                                            </Typography>
                                                        }
                                                        secondary={formatFileSize(file.size)}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
                                    {!isReservationPast(expandedReservation) && (
                                        <>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    const selectedReservation = reservations.find(res => res.id === expandedReservation.id);
                                                    if (selectedReservation) {
                                                        handleMergeTime(selectedReservation);
                                                    }
                                                }}
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    padding: '6px 12px',
                                                    borderRadius: '8px',
                                                    minWidth: '120px',
                                                    height: 40,
                                                    background: 'linear-gradient(45deg, #1D3557 30%, #457B9D 90%)',
                                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                                    '&:hover': {
                                                        background: 'linear-gradient(45deg, #1D3557 10%, #457B9D 70%)',
                                                    },
                                                }}
                                            >
                                                Редактировать
                                            </Button>
                                            <DeleteButton onClick={() => handleDeleteClick(expandedReservation.id)} />
                                        </>
                                    )}
                                </Box>
                            </DialogContent>
                        </Dialog>
                    )}

                    <Dialog
                        sx={{
                            '& .MuiDialog-paper': {
                                width: '80%',
                                borderRadius: '12px',
                            },
                        }}
                        open={confirmDeleteOpen}
                        onClose={handleCancelDelete}>
                        <DialogTitle>Подтверждение</DialogTitle>
                        <DialogContent>
                            <Typography variant="body2">Вы уверены, что хотите удалить это мероприятие?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelDelete} color="primary">
                                Отмена
                            </Button>
                            <Button onClick={handleConfirmDelete} color="error">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </AnimatedBox>
            <Dialog
                sx={{
                    '& .MuiDialog-paper': {
                        width: '80%',
                        borderRadius: '12px',
                    },
                }}
                open={openConfirmDialog}
                onClose={handleCancelOpen}>
                <DialogTitle>Скачивание файла</DialogTitle>
                <DialogContent>
                    <Typography>Вы собираетесь скачать файл "{fileNameToOpen}". Продолжить?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelOpen} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleConfirmOpen} color="primary">
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2500}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
                message={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                        <Typography variant="body2" sx={{ color: '#333' }}>
                            Бронирование успешно удалено
                        </Typography>
                    </Box>
                }
            />
        </>
    );

    function isReservationPast(reservation) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const now = new Date();

        const reservationDate = new Date(reservation.data);
        reservationDate.setHours(0, 0, 0, 0);

        if (reservationDate > today) {
            return false;
        } else if (reservationDate < today) {
            return true;
        } else {
            const endTimes = reservation.reservation_time.map(time => time.split('-')[1]);
            const latestEndTimeStr = endTimes[endTimes.length -1];
            const [endHour, endMinute] = latestEndTimeStr.split(':').map(Number);
            const reservationEndDateTime = new Date(reservationDate);
            reservationEndDateTime.setHours(endHour, endMinute, 0, 0);

            return now > reservationEndDateTime;
        }
    }
};

export default MyReservationsScreen;

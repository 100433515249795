import { combineReducers } from 'redux';
import productsSlice from './productSlice';
import authSlice from "./authSlice";
import reservationSlice from "./reservationSlice";
import myReservationSlice from "./myReservationSlice";
import usersListSlice from "./userListSlice";
import interceptionSlice from "./interceptionSlice";
import deputiesSlice from "./deputieSlice";

const rootReducer = combineReducers({
    products: productsSlice,
    auth: authSlice,
    reservations: reservationSlice,
    myReservations: myReservationSlice,
    usersList: usersListSlice,
    interceptionList: interceptionSlice,
    deputies: deputiesSlice,
});

export default rootReducer;

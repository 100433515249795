import React, { useEffect, useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    CardContent, Snackbar, Alert,
} from '@mui/material';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import TimeSlotSelector from '../../components/TimeSlotSelector/TimeSlotSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchReservations } from '../../service/redux/reducers/reservationSlice';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedBox from '../../components/MotionChild/AnimatedBox';

const RoomReservationScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedRoom, reservation, isEditing } = location.state || {};
    const reservationData = Array.isArray(reservation) ? reservation[0] : reservation;
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
    const { reservations, loading } = useSelector((state) => state.reservations);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    useEffect(() => {
        let roomIds = [];

        if (isEditing) {
            roomIds = [reservationData.product_id];
        } else if (Array.isArray(selectedRoom)) {
            roomIds = selectedRoom.map((room) => room.id);
        } else if (selectedRoom) {
            roomIds = [selectedRoom.id];
        }

        if (roomIds.length > 0) {
            const formattedDate = selectedDate.format('YYYY-MM-DD');
            dispatch(fetchReservations({ roomIds, date: formattedDate }));
        }
        // eslint-disable-next-line
    }, [reservationData, selectedRoom, dispatch]);

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        setSelectedTimeSlots([]);
        let roomIds = [];
        if (isEditing) {
            roomIds = [reservationData.product_id];
        } else if (Array.isArray(selectedRoom)) {
            roomIds = selectedRoom.map((room) => room.id);
        } else if (selectedRoom) {
            roomIds = [selectedRoom.id];
        }

        if (roomIds.length > 0) {
            const formattedDate = newValue.format('YYYY-MM-DD');
            dispatch(fetchReservations({ roomIds, date: formattedDate }));
        }
    };

    const handleReservationSubmit = (room) => {
        navigate('/rooms/booking-form', {
            state: {
                selectedRoom: room,
                selectedDate: selectedDate.toISOString(),
                selectedTimeSlots: selectedTimeSlots,
                isEditing,
                reservation,
            },
        });
    };

    const roomReservations = useMemo(() => {
        const mapping = {};
        reservations.forEach((reservation) => {
            const roomId = reservation.product_id;
            if (!mapping[roomId]) {
                mapping[roomId] = [];
            }
            mapping[roomId].push(...reservation.reservation_time);
        });
        return mapping;
    }, [reservations]);

    const [availableRooms, setAvailableRooms] = useState(Array.isArray(selectedRoom) ? selectedRoom : []);

    useEffect(() => {
        if (Array.isArray(selectedRoom)) {
            if (selectedTimeSlots.length === 0) {
                setAvailableRooms([]);
            } else {
                const roomsAvailableAtSelectedTime = selectedRoom.filter((room) => {
                    const roomReservedTimeSlots = roomReservations[room.id] || [];
                    return !selectedTimeSlots.some((timeSlot) =>
                        roomReservedTimeSlots.includes(timeSlot)
                    );
                });
                setAvailableRooms(roomsAvailableAtSelectedTime);
            }
        }
    }, [selectedTimeSlots, roomReservations, selectedRoom]);

    return (
        <AnimatedBox delay={0.1}>
            <Box sx={{ overflowY: 'auto' }}>
                <Typography variant="h6" gutterBottom>
                    Выбор даты и времени {selectedRoom?.name}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                        <DateCalendar
                            value={selectedDate}
                            onChange={handleDateChange}
                            minDate={dayjs()}
                            views={['day']}
                            showDaysOutsideCurrentMonth
                            fixedWeekNumber={6}
                            componentsProps={{
                                switchViewButton: { disabled: true },
                            }}
                            slots={{
                                leftArrowButton: Button,
                                rightArrowButton: Button,
                            }}
                            sx={{
                                height: 'auto !important',
                                minHeight: 'auto !important',
                                width: '100% !important',
                                '& .MuiPickersCalendarHeader-label': {
                                    color: 'black !important',
                                },
                                '& .MuiPickersCalendarHeader-root': {
                                    width: '100% !important',
                                    padding: '0 !important',
                                    marginTop: '0 !important',
                                },
                                '& .MuiDayCalendar-root': {
                                    width: '100% !important',
                                    margin: '0 !important',
                                },
                                '& .MuiDayCalendar-weekContainer': {
                                    margin: '0 !important',
                                    padding: '0 !important',
                                },
                                '& .MuiPickersSlideTransition-root': {
                                    minHeight: '230px !important',
                                    height: '230px !important',
                                },
                                '& .MuiDayCalendar-monthContainer': {
                                    alignItems: 'center',
                                },
                            }}
                            renderDay={(day, _value, DayComponentProps) => (
                                <Button
                                    {...DayComponentProps}
                                    sx={{ width: '100%', minWidth: '0', padding: '0' }}
                                >
                                    {day.getDate()}
                                </Button>
                            )}
                        />
                    </LocalizationProvider>
                    <TimeSlotSelector
                        selectedTimeSlots={selectedTimeSlots}
                        setSelectedTimeSlots={setSelectedTimeSlots}
                        selectedRooms={Array.isArray(selectedRoom) ? selectedRoom : [selectedRoom]}
                        roomReservations={roomReservations}
                        selectedDate={selectedDate.toDate()}
                        reservationData={reservationData}
                        loading={loading}
                        reservations={reservations}
                        openSnackbar={openSnackbar}
                    />
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        sx={{
                            position: 'fixed',
                            top: 12,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 999,
                            width: '90%',
                        }}
                        ContentProps={{
                            sx: {
                                backgroundColor: '#fff',
                                color: '#333',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                                padding: '8px 16px',
                                fontSize: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }}
                    >
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
                {!Array.isArray(selectedRoom) && selectedRoom && (
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleReservationSubmit(selectedRoom)}
                        disabled={selectedTimeSlots.length === 0}
                    >
                        Забронировать
                    </Button>
                )}
                {Array.isArray(selectedRoom) && selectedTimeSlots.length > 0 ? (
                    <Box>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Доступные залы
                        </Typography>
                        {availableRooms.length > 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '1em',
                                    justifyContent: 'center',
                                    marginTop: '0.5em',
                                    marginBottom: '0.2em',
                                }}
                            >
                                {availableRooms.map((room) => (
                                    <Card
                                        key={room.id}
                                        sx={{ width: '120px', borderRadius: '8px', overflow: 'hidden' }}
                                        onClick={() => handleReservationSubmit(room)}
                                    >
                                        <CardMedia
                                            component="img"
                                            image={room.image_link}
                                            alt={room.label}
                                            sx={{ width: '100%', height: '70px' }}
                                        />
                                        <CardContent
                                            sx={{
                                                padding: '0.5em',
                                                textAlign: 'center',
                                                '&:last-child': { padding: 0 },
                                            }}
                                        >
                                            <Typography variant="subtitle2" noWrap>
                                                {room.label}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>
                        ) : (
                            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                                Нет доступных залов на выбранное время.
                            </Typography>
                        )}
                    </Box>
                ) : (
                    isEditing && (
                        <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleReservationSubmit(selectedRoom)}
                            disabled={selectedTimeSlots.length === 0}
                        >
                            Перенести
                        </Button>
                    )
                )}
            </Box>
        </AnimatedBox>
    );
};

export default RoomReservationScreen;

import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Chip,
    Paper,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    LinearProgress,
    IconButton, InputLabel, FormControl, Select, MenuItem,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import { formatSelectedTime } from "../../utils/formatSelectedTime";
import { makeReservation, editReservation, uploadFiles } from "../../api/api";
import successGif from '../../common/lottieGifs/successGif.json';
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../service/redux/reducers/userListSlice";
import { roleEmojis } from "../../utils/roleEmojis";
import { CloudUpload, Delete } from '@mui/icons-material';
import {fetchDeputies} from "../../service/redux/reducers/deputieSlice";

const BookingFormScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {selectedRoom, selectedDate, selectedTimeSlots, isEditing, reservation} = location.state || {};
    const reservationData = Array.isArray(reservation) ? reservation[0] : reservation;
    const [organizerName, setOrganizerName] = useState(isEditing ? reservationData?.label || '' : '');
    const [maintenanceIds, setMaintenanceIds] = useState(isEditing ? reservationData?.maintenance_ids || [] : []);
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState(isEditing ? reservationData?.emails || [] : []);
    const [countParticipants, setCountParticipants] = useState(isEditing ? reservationData?.count_participants || '' : ''); // Новое состояние
    const [isSuccess, setIsSuccess] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [selectedDeputies, setSelectedDeputies] = useState(
        isEditing && reservationData?.depute
            ? { full_name: reservationData.depute }
            : []
    );
    const {userList} = useSelector((state) => state.usersList);
    const {deputiesList} = useSelector((state) => state.deputies);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadStatuses, setUploadStatuses] = useState({});

    useEffect(() => {
        if (isEditing && reservationData?.files) {
            const existingFiles = reservationData.files.map(file => ({
                file: null,
                id: file.id,
                uniqueKey: file.id,
                isExisting: true,
                fileName: file.file_name,
                size: file.size,
            }));
            setSelectedFiles(existingFiles);
        }
    }, [isEditing, reservationData]);

    useEffect(() => {
        dispatch(fetchUserList({privileges: ["foreman", "secretary"]}));
        dispatch(fetchDeputies());
    }, [dispatch]);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const getRoleEmoji = (privileges) => {
        const role = privileges.find((privilege) => roleEmojis[privilege]);
        return role ? roleEmojis[role].emoji : '';
    };

    const handleEmailInputChange = (event) => {
        setEmailInput(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            if (emailInput.trim()) {
                if (!isValidEmail(emailInput.trim())) {
                    setSnackbarMessage('Почта не валидна');
                    setSnackbarOpen(true);
                } else if (!emails.includes(emailInput.trim())) {
                    setEmails((prevEmails) => [...prevEmails, emailInput.trim()]);
                    setEmailInput('');
                }
            }
            event.preventDefault();
        }
    };

    const handleEmailDelete = (emailToDelete) => {
        setEmails((prevEmails) => prevEmails.filter((email) => email !== emailToDelete));
    };

    const handleSubmit = async () => {
        const allUploaded = Object.values(uploadStatuses).every(status => status.status === 'uploaded');
        if (!allUploaded) {
            setSnackbarMessage('Пожалуйста, дождитесь завершения загрузки файлов.');
            setSnackbarOpen(true);
            return;
        }

        if (emailInput.trim() && isValidEmail(emailInput.trim()) && !emails.includes(emailInput.trim())) {
            setEmails((prevEmails) => [...prevEmails, emailInput.trim()]);
        } else if (emailInput.trim() && !isValidEmail(emailInput.trim())) {
            setSnackbarMessage('Почта не валидна');
            setSnackbarOpen(true);
            return;
        }

        const updatedEmails = [...emails, emailInput.trim()].filter((email) => email);

        if (countParticipants === '' || isNaN(countParticipants) || Number(countParticipants) <= 0) {
            setSnackbarMessage('Пожалуйста, введите корректное количество участников.');
            setSnackbarOpen(true);
            return;
        }

        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');

        const fileIds = selectedFiles
            .filter(fileObj => fileObj.id)
            .map(fileObj => fileObj.id);

        const requestData = {
            id: isEditing ? reservationData?.id : undefined,
            product_id: selectedRoom?.id || reservationData?.product?.id,
            label: organizerName,
            data: formattedDate,
            reservation_time: selectedTimeSlots,
            emails: updatedEmails,
            maintenance_ids: maintenanceIds,
            count_participants: Number(countParticipants),
            created_by: isEditing ? reservationData?.created_by : '00000000-0000-0000-0000-000000000000',
            files_ids: fileIds.length > 0 ? fileIds : [],
            depute: selectedDeputies.full_name,
        };

        try {
            if (isEditing) {
                await editReservation(requestData);
            } else {
                await makeReservation(requestData);
            }
            setIsSuccess(true);
        } catch (error) {
            if (error.response?.status === 409 || error.message === 'Failed to make a reservation') {
                setSnackbarMessage('Выбранное время уже занято');
            } else {
                setSnackbarMessage('Произошла ошибка. Попробуйте еще раз.');
            }
            setSnackbarOpen(true);
        }
    };

    const handleNavigateToReservations = () => {
        navigate("/reservation/myreservation", { state: { isMy: true } });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleUserDialogOpen = () => {
        setUserDialogOpen(true);
    };

    const handleUserDialogClose = () => {
        setUserDialogOpen(false);
    };

    const handleUserToggle = (userId) => {
        setMaintenanceIds((prevIds) =>
            prevIds.includes(userId)
                ? prevIds.filter((id) => id !== userId)
                : [...prevIds, userId]
        );
    };

    const filteredUsers = userList.filter((user) =>
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(filterText.toLowerCase())
    );

    const generateUniqueFileKey = (file) => {
        return `${file.name}-${file.size}-${file.lastModified}-${Math.random()}`;
    };

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = [];
        const newUploadStatuses = {...uploadStatuses};

        files.forEach((file) => {
            if (file.size > 20 * 1024 * 1024) { // 20MB limit
                setSnackbarMessage(`Файл ${file.name} превышает лимит в 20MB.`);
                setSnackbarOpen(true);
            } else if (!selectedFiles.some(f => f.file?.name === file.name && f.file?.size === file.size)) {
                const uniqueKey = generateUniqueFileKey(file);
                validFiles.push({file, id: null, uniqueKey, isExisting: false});
                newUploadStatuses[uniqueKey] = {progress: 0, status: 'pending'};
            }
        });

        if (validFiles.length > 0) {
            setSelectedFiles(prev => [...prev, ...validFiles]);
            setUploadStatuses(newUploadStatuses);
        }
        event.target.value = null;
    };

    const handleFileUpload = async (fileObj) => {
        const {file, uniqueKey} = fileObj;
        const product_id = selectedRoom?.id || reservationData?.product?.id;
        if (!product_id) {
            setSnackbarMessage('Не выбран зал для загрузки файлов.');
            setSnackbarOpen(true);
            return;
        }

        setUploadStatuses(prev => ({
            ...prev,
            [uniqueKey]: {...prev[uniqueKey], status: 'uploading'},
        }));

        try {
            const response = await uploadFiles(product_id, [file], (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadStatuses(prev => ({
                    ...prev,
                    [uniqueKey]: {...prev[uniqueKey], progress},
                }));
            });

            const uploadedFileId = response.data.data.id;

            setSelectedFiles(prev => prev.map(f => {
                if (f.uniqueKey === uniqueKey) {
                    return {...f, id: uploadedFileId};
                }
                return f;
            }));

            setUploadStatuses(prev => ({
                ...prev,
                [uniqueKey]: {...prev[uniqueKey], status: 'uploaded', progress: 100},
            }));
        } catch (error) {
            setUploadStatuses(prev => ({
                ...prev,
                [uniqueKey]: {...prev[uniqueKey], status: 'error'},
            }));
            setSnackbarMessage(`Не удалось загрузить файл ${file.name}.`);
            setSnackbarOpen(true);
        }
    };

    const handleUploadAllFiles = () => {
        selectedFiles.forEach(fileObj => {
            if (fileObj.isExisting) {
                return;
            }
            if (uploadStatuses[fileObj.uniqueKey].status === 'pending') {
                handleFileUpload(fileObj);
            }
        });
    };

    const handleFileDelete = (uniqueKey) => {
        setSelectedFiles(prev => prev.filter(file => file.uniqueKey !== uniqueKey));
        setUploadStatuses(prev => {
            const updated = {...prev};
            delete updated[uniqueKey];
            return updated;
        });
    };

    useEffect(() => {
        if (selectedFiles.length > 0) {
            handleUploadAllFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFiles]);

    if (isSuccess) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2}}>
                <Lottie animationData={successGif} style={{width: 150, height: 150}}/>
                <Typography variant="h6" gutterBottom>
                    {isEditing ? 'Изменения сохранены' : 'Вы успешно забронировали'}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {!isEditing && (selectedRoom?.label || reservationData?.product?.label)}
                </Typography>
                <Button
                    onClick={handleNavigateToReservations}
                    variant="contained"
                    color="primary"
                    sx={{mt: 2}}
                >
                    К моим бронированиям
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{padding: 2}}>
            <Typography variant="h6" gutterBottom>
                Информация о бронировании
            </Typography>
            <Typography variant="body1">
                Зал: {selectedRoom?.label || (isEditing && reservationData?.product?.label)}
            </Typography>
            <Typography variant="body1">
                Дата: {dayjs(selectedDate).format('DD.MM.YYYY')}
            </Typography>
            <Typography variant="body1">
                {formatSelectedTime(selectedTimeSlots)}
            </Typography>
            <TextField
                label="Название мероприятия"
                value={organizerName}
                onChange={(e) => setOrganizerName(e.target.value)}
                fullWidth
                margin="normal"
                required
            />

            <Button onClick={handleUserDialogOpen} variant="outlined" fullWidth sx={{mt: 2}}>
                {maintenanceIds.length
                    ? userList
                        .filter((user) => maintenanceIds.includes(user.id))
                        .map((user) => `${getRoleEmoji(user.privileges)} ${user.first_name} ${user.last_name}`)
                        .join(', ')
                    : 'Выбрать ответственных'}
            </Button>

            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="deputy-select-label">Заместители *</InputLabel>
                <Select
                    labelId="deputy-select-label"
                    label="Заместители *"
                    value={selectedDeputies}
                    onChange={(event) => {
                        const selectedDeputy = event.target.value;
                        setSelectedDeputies(selectedDeputy);
                    }}
                    renderValue={(selected) => selected.full_name}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: '16px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            },
                        },
                    }}
                >
                    {deputiesList.map((deputy) => (
                        <MenuItem
                            key={deputy.id}
                            value={deputy}
                            sx={{
                                '&:hover': { backgroundColor: '#457B9D', color: '#fff' },
                                '&.Mui-selected': {
                                    backgroundColor: '#457B9D',
                                    color: '#fff',
                                    '&:hover': { backgroundColor: '#457B9D' },
                                },
                            }}
                        >
                            <ListItemText primary={deputy.full_name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                label="Кол-во участников"
                type="text"
                value={countParticipants}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                        setCountParticipants(value);
                    }
                }}
                fullWidth
                required
                margin="normal"
                inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                }}
            />


            <Dialog open={userDialogOpen} onClose={handleUserDialogClose} fullWidth>
                <DialogTitle>Выберите ответственных</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Фильтр"
                        value={filterText}
                        onChange={(event) => setFilterText(event.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <List>
                        {filteredUsers.map((user) => (
                            <ListItem key={user.id} button onClick={() => handleUserToggle(user.id)}>
                                <ListItemText
                                    primary={`${getRoleEmoji(user.privileges)} ${user.first_name} ${user.last_name}`}/>
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        checked={maintenanceIds.includes(user.id)}
                                        onChange={() => handleUserToggle(user.id)}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUserDialogClose} variant="contained" color="primary">
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper
                sx={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexWrap: 'wrap',
                    padding: 0.2,
                    marginTop: 2,
                    minHeight: '60px',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
            >
                {emails.map((email) => (
                    <Chip
                        key={email}
                        label={email}
                        onDelete={() => handleEmailDelete(email)}
                        sx={{margin: '5px'}}
                    />
                ))}
                <TextField
                    label="Участники"
                    value={emailInput}
                    onChange={handleEmailInputChange}
                    onKeyPress={handleKeyPress}
                    fullWidth
                    variant="outlined"
                    sx={{margin: '5px'}}
                />
            </Paper>

            <Box sx={{marginTop: 4}}>
                <input
                    accept="*"
                    style={{display: 'none'}}
                    id="file-upload-input"
                    multiple
                    type="file"
                    onChange={handleFileSelect}
                />
                <label htmlFor="file-upload-input">
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<CloudUpload/>}
                    >
                        Выбрать файлы
                    </Button>
                </label>
                <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                    Можно загрузить несколько файлов, каждый не более 20MB.
                </Typography>

                <List sx={{ mt: 2 }}>
                    {selectedFiles.map((fileObj) => (
                        <ListItem
                            key={fileObj.uniqueKey}
                            sx={{
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        noWrap
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {fileObj.isExisting ? fileObj.fileName : fileObj.file.name}
                                    </Typography>
                                }
                                secondary={
                                    fileObj.isExisting
                                        ? `${(fileObj.size / (1024 * 1024)).toFixed(2)} MB`
                                        : `${(fileObj.file.size / (1024 * 1024)).toFixed(2)} MB`
                                }
                                sx={{
                                    flex: 1,
                                    marginRight: 2,
                                }}
                            />
                            <Box sx={{ width: '40%', mr: 2, minWidth: '120px' }}>
                                {fileObj.isExisting ? (
                                    <Typography variant="body2" color="success.main">
                                        Уже загружено
                                    </Typography>
                                ) : (
                                    <>
                                        {uploadStatuses[fileObj.uniqueKey]?.status === 'uploading' && (
                                            <LinearProgress
                                                variant="determinate"
                                                value={uploadStatuses[fileObj.uniqueKey]?.progress || 0}
                                            />
                                        )}
                                        {uploadStatuses[fileObj.uniqueKey]?.status === 'uploaded' && (
                                            <Typography variant="body2" color="success.main">
                                                Загружено
                                            </Typography>
                                        )}
                                        {uploadStatuses[fileObj.uniqueKey]?.status === 'error' && (
                                            <Typography variant="body2" color="error.main">
                                                Ошибка
                                            </Typography>
                                        )}
                                        {uploadStatuses[fileObj.uniqueKey]?.status === 'pending' && (
                                            <Typography variant="body2" color="textSecondary">
                                                Ожидание загрузки
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </Box>
                            <IconButton edge="end" onClick={() => handleFileDelete(fileObj.uniqueKey)}>
                                <Delete />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Button
                onClick={handleSubmit}
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                    !organizerName.trim() ||
                    !countParticipants ||
                    Number(countParticipants) <= 0 ||
                    !selectedDeputies ||
                    (Array.isArray(selectedDeputies) && selectedDeputies.length === 0)
                }
            >
                {isEditing ? 'Изменить данные бронирования' : 'Забронировать'}
            </Button>

            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={1500}
                onClose={handleCloseSnackbar}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default BookingFormScreen;
